/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import COLORS from "constants/Colors";
import ARROW from "assets/icons/right-arrow.svg";
import Text from "../Text";

import styles from "./styles.module.scss";
import Image from "../Image";

interface CollapsibleProps {
  title: string;
  children: React.ReactNode;
  showArrow?: boolean;
  initialState?: boolean; // Optional initial state (default: collapsed)
}
export default function Collapse({
  title,
  children,
  showArrow = true,
  initialState = false,
}: CollapsibleProps) {
  const [isOpen, setIsOpen] = useState(initialState);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <div className={`${styles.container} my-2`}>
      <div className={`${styles.collapse} w-100`} onClick={toggle}>
        <div className="d-flex justify-content-between align-items-center">
          <Text className={styles.bold}>{title}</Text>
          {showArrow && (
            <Image
              src={ARROW}
              style={{ transform: isOpen ? "rotate(-90deg)" : "rotate(90deg)" }}
            />
          )}
        </div>
        {isOpen && (
          <div className="w-75 mt-2" style={{ color: COLORS.grey }}>
            {children}
          </div>
        )}
      </div>
    </div>
  );
}
