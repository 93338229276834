/* eslint-disable jsx-a11y/control-has-associated-label */
import { useState } from "react";
import { NavLink } from "react-router-dom";
import Icon from "components/general/Icon";
import { BiArrowToRight } from "react-icons/bi";
import COLORS from "constants/Colors";
import getClassNames from "utils/getClassName";
import Navbar from "components/general/Navbar";
import useGetUserInfo from "hooks/useGetUserInfo";
import { NavigationListItem } from "./types";
import styles from "./styles.module.scss";

export default function SidebarLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const { role } = useGetUserInfo();

  const [isOpen, setIsOpen] = useState(true);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const contentStyle = {
    paddingInlineStart: isOpen ? "161px" : "50px", // Adjust the width as needed
    transition: "padding-inline-start 300ms ease-in-out", // Add a transition
  };

  const toggleBtnClassName = getClassNames([
    "shadow-sm",
    `${styles["toggle-btn"]}`,
    `${isOpen ? styles.open : ""}`,
  ]);

  const rawNavigationList: NavigationListItem[] = [
    {
      title: "Dashboard",
      iconName: "home",
      href: "/",
    },
    {
      title: "Reports",
      iconName: "reports",
      href: "/reports",
    },
    {
      title: "Request",
      iconName: "note",
      href: "/requests",
    },
    {
      title: "Calender",
      iconName: "calendar",
      href: "/calender",
    },
    {
      title: "Shifts",
      iconName: "note",
      href: "/shifts",
    },
    {
      title: "Staff Directory",
      iconName: "users",
      href: "/staff-directory",
    },
    {
      title: "Activity",
      iconName: "active-note",
      href: "/activity",
    },
    {
      title: "Settings",
      iconName: "setting",
      href: "/settings",
      isForAdmins: true,
    },
    {
      title: "Intergration",
      iconName: "integration",
      href: "/integration",
    },
  ];

  const employeeRawNavigationList: NavigationListItem[] = [
    {
      title: "Dashboard",
      iconName: "home",
      href: "/",
    },
    {
      title: "Request",
      iconName: "note",
      href: "/requests",
    },
    {
      title: "Calender",
      iconName: "calendar",
      href: "/calender",
    },
    {
      title: "Shifts",
      iconName: "note",
      href: "/shifts",
    },
    {
      title: "Activity",
      iconName: "active-note",
      href: "/activity",
    },
  ];

  const navigationListMap = {
    admin: rawNavigationList,
    manager: rawNavigationList?.filter((item) => !item.isForAdmins),
    employee: employeeRawNavigationList,
  };

  const navigationList = role ? navigationListMap[role] : [];

  return (
    <div className="h-100">
      <Navbar />
      <aside className={`${styles.sidenav} ${isOpen ? styles.open : ""}`}>
        <button
          className={toggleBtnClassName}
          onClick={toggleSidebar}
          type="button"
          title="toggle sidebar"
        >
          <BiArrowToRight size={21} color={COLORS.info} />
        </button>

        <div className={`w-100 ${styles.navigationContent}`}>
          {navigationList?.map((item) => (
            <NavLink
              key={item?.title}
              to={item?.href}
              className={({ isActive }) => (isActive ? styles.active : "")}
            >
              {({ isActive }) => (
                <>
                  <div className={styles.activeVerticalBar} />
                  <Icon
                    name={item?.iconName}
                    size={24}
                    color={isActive ? "primary" : "info"}
                  />
                  <span className={isOpen ? styles.open : ""}>
                    {item?.title}
                  </span>
                </>
              )}
            </NavLink>
          ))}
        </div>
      </aside>

      <main className={styles.sidelayoutContent} style={contentStyle}>
        {children}
      </main>
    </div>
  );
}
