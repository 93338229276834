import { useSelector } from "react-redux";
import { RootState } from "reducers";
import UserInfo from "components/cards/UserInfo";

import COLORS from "constants/Colors";
import InfoRow from "components/general/InfoRow";
import Paper from "components/general/PaperComponent";
import SeparatedRowElements from "components/general/SeparatedRowElements";
import Collapse from "components/general/Collapse";
import Text from "components/general/Text";

import ModalWrapper from "../../ModalWrapper";
import styles from "../styles.module.scss";

export default function Leave({
  containerStyle,
  isVisible,
  setIsVisible,
}: any) {
  const userData = useSelector((state: RootState) => state.auth.userData);

  const text = `- it can be found as a welcome guest in many households across the world.`;

  const items = [
    {
      key: "1",
      label: "This is panel header 1",
      children: (
        <div style={{ display: "grid" }}>
          <Text>{text}</Text>
          <Text>{text}</Text>
          <Text>{text}</Text>
        </div>
      ),
    },
    {
      key: "2",
      label: "This is panel header 2",
      children: <p>{text}</p>,
    },
    {
      key: "3",
      label: "This is panel header 3",
      children: <p>{text}</p>,
    },
  ];
  return (
    <ModalWrapper
      size="large"
      headerTitle="Leave Balance"
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      isFooterHidden
    >
      <div className={`${styles.container} ${containerStyle}`}>
        <div className={styles.memberContent}>
          <UserInfo data={userData} />
        </div>

        <Paper
          backgroundColor={COLORS.light}
          borderColor={COLORS.lightGrey}
          paddingHorizontal={32}
        >
          <SeparatedRowElements containerStyle="border-0 pb-0 mb-2">
            <InfoRow
              title="All:"
              info={`${25} Days`}
              infoFontSize={18}
              infoFontWeight="500"
              containerStyle="py-2"
            />
            <InfoRow
              title="Available:"
              info={`${25} Days`}
              infoFontSize={18}
              infoFontWeight="500"
              containerStyle="py-2"
            />
            <InfoRow
              title="Used:"
              info={`${25} Days`}
              infoFontSize={18}
              infoFontWeight="500"
              containerStyle="py-2"
            />
          </SeparatedRowElements>
        </Paper>
        <div className="mt-3">
          {items.map((item) => (
            <Collapse title={item.label}>{item.children}</Collapse>
          ))}
        </div>
        {/* <hr /> */}
      </div>
    </ModalWrapper>
  );
}
