/* eslint-disable */

import ModalWrapper from "components/modals/ModalWrapper";
import { Form } from "antd";
import showSuccessMsg from "utils/showSuccessMsg";
import { useGetZonesQuery } from "apis/services/zone";
import SelectionInput from "components/inputs/SelectionInput";
import {
  useAddWorkflowMutation,
  useEditWorkflowMutation,
} from "apis/services/workflow";
import {
  useGetDepartmentsQuery,
  useGetProjectsQuery,
} from "apis/services/locations";
import { useGetAllStaffQuery } from "apis/services/staff";
import { useEffect } from "react";

export default function AddNewWorkflow({
  isVisible,
  setIsVisible,
  isUpdate,
  initialValues,
}: any) {
  const [form] = Form.useForm();
  const zoneValue = Form.useWatch("zone", form);

  const { data: zones } = useGetZonesQuery({ page_size: 100 });
  const { data: departments } = useGetDepartmentsQuery({ page_size: 100 });
  const { data: projects } = useGetProjectsQuery(
    {
      page_size: 100,
      zone: zoneValue,
    },
    { skip: !zoneValue }
  );
  const { data: managers } = useGetAllStaffQuery({
    role: "manager",
    page_size: 100,
  });

  useEffect(() => {
    form.resetFields();
  }, [isVisible]);

  const [addWorkflow, { isLoading: isAddWorkflowLoading }] =
    useAddWorkflowMutation();
  const [editWorkflow, { isLoading: isEditWorkflowLoading }] =
    useEditWorkflowMutation();

  const departmentOptions =
    departments?.results?.map((item: any) => ({
      label: item?.name,
      value: item?.id,
    })) || [];
  const adminsOptions =
    managers?.results?.map((item: any) => ({
      label: `${item?.user?.username} (${item?.cfm_id})`,
      value: item?.id,
    })) || [];
  const zoneOptions =
    zones?.results?.map((item: any) => ({
      label: item.name,
      value: item.id,
    })) || [];
  const projectOptions =
    projects?.results?.map((item: any) => ({
      label: `${item.name} (${item.project_id})`,
      value: item.id,
    })) || [];

  const handleSubmit = (values: any) => {
    const requestType = isUpdate ? editWorkflow : addWorkflow;
    const requested_data = { ...values };
    if (isUpdate) {
      requested_data.id = initialValues.id;
    }
    requestType(requested_data)
      .unwrap()
      .then(() => {
        setIsVisible(false);
        form.resetFields();
        showSuccessMsg({ msg: "Added new workflow successfully!" });
      });
  };

  const submitForm = () => {
    form.submit();
  };
  // console.log(initialValues);

  return (
    <ModalWrapper
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      size="large"
      headerTitle="Add New Workflow"
      onConfirm={submitForm}
      isLoading={isAddWorkflowLoading}
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        initialValues={isUpdate ? initialValues : {}}
        layout="vertical"
        scrollToFirstError
        className="w-100"
      >
        <Form.Item name="zone" label="Zone" rules={[{ required: true }]}>
          <SelectionInput options={zoneOptions} />
        </Form.Item>

        <Form.Item
          name="project"
          label="Default Project"
          rules={[{ required: true }]}
        >
          <SelectionInput options={projectOptions} />
        </Form.Item>

        <Form.Item
          name="department"
          label="Department"
          rules={[{ required: true }]}
        >
          <SelectionInput options={departmentOptions} />
        </Form.Item>

        <Form.Item name="user" label="Person" rules={[{ required: true }]}>
          <SelectionInput options={adminsOptions} />
        </Form.Item>
      </Form>
    </ModalWrapper>
  );
}
