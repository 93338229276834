import { useParams } from "react-router-dom";
import { useState } from "react";

import { useGetStaffMemberInfoQuery } from "apis/services/staff";
import COLORS from "constants/Colors";
import Paper from "components/general/PaperComponent";
import SeparatedRowElements from "components/general/SeparatedRowElements";
import InfoRow from "components/general/InfoRow";
import UserInfo from "components/cards/UserInfo";
import SecondaryPageHeader from "components/general/SecondaryPageHeader";
import FilterButton from "components/general/FilterButton";
import Button from "components/general/Button";
import CalendarRow from "components/general/CalendarRow";
import Image from "components/general/Image";
import RED_DOWN_ARROW from "assets/icons/down-red-arrow.svg";
import Leave from "components/modals/Profile/Leave";
import styles from "./styles.module.scss";

export default function ProfileLeaveBalance() {
  const { id } = useParams();
  const { data } = useGetStaffMemberInfoQuery(id!);
  const [leaveModalshown, setleaveModalshown] = useState(false);

  const INFODATA = [
    {
      title: "Present",
      info: 255,
    },
    {
      title: "Permissions",
      info: 43,
    },
    {
      title: "Absent",
      info: 7,
    },
  ];

  return (
    <div className={styles.staffMemberInfoContainer}>
      <Paper
        backgroundColor={COLORS.lightDangerBackground}
        borderColor={COLORS.lightDangerBorder}
        paddingVertical={20}
        paddingHorizontal={32}
      >
        <UserInfo data={data} />
      </Paper>

      <div className="my-4">
        <SeparatedRowElements>
          {INFODATA.map((item) => (
            <InfoRow
              key={item.title}
              title={`${item.title}:`}
              info={`${item.info} days`}
              infoFontSize={18}
              infoFontWeight="500"
              containerStyle="py-2"
            />
          ))}
        </SeparatedRowElements>
      </div>
      <Paper
        backgroundColor={COLORS.light}
        borderColor={COLORS.lightGrey}
        paddingVertical={20}
        paddingHorizontal={32}
      >
        <div>
          <SecondaryPageHeader
            title="Leave Balance"
            RightComponent={
              <div className="d-flex flex-row">
                <div className="mx-2">
                  <FilterButton onClick={() => {}} filtersToHide={[]} />
                </div>
                <Button
                  isOutline
                  color="primary"
                  suffix={<Image src={RED_DOWN_ARROW} />}
                >
                  Action
                </Button>
              </div>
            }
          />
          {["permission", "permission", "permission", "permission"].map(
            (item) => (
              <CalendarRow
                onClickHandler={() => setleaveModalshown(true)}
                dataItem={{
                  id: 15,
                  state: item,
                  date: "15/15/15",
                }}
              />
            )
          )}
        </div>
      </Paper>
      <Leave isVisible={leaveModalshown} setIsVisible={setleaveModalshown} />
    </div>
  );
}
