/* eslint-disable */

import { Form } from "antd";
import TextInput from "components/inputs/TextInput";

import SelectionInput from "components/inputs/SelectionInput";

import RadioGroupInput from "components/inputs/RadioGroupInput";
import CheckboxGroupInput from "components/inputs/CheckboxGroupInput";
import showSuccessMsg from "utils/showSuccessMsg";
import {
  useGetLeavePolicyQuery,
  usePostRequestTypeMutation,
  useUpdateRequestTypeMutation,
} from "apis/services/other";
import { AddNewRequestProps } from "./types";
import ModalWrapper from "../ModalWrapper";
import styles from "./styles.module.scss";
import IMPORTANT_VARS from "constants/ImportantVars";
import { useState } from "react";

export default function AddNewRequest({
  containerStyle,
  isVisible,
  setIsVisible,
  isUpdate,
  initValues = {},
}: AddNewRequestProps) {
  const [form] = Form.useForm();
  const [rateFactorState, setRateFactorState] = useState(
    initValues ? (initValues.group == "leave" ? "days" : "hours") : ""
  );
  const [createTypeTemplate] = usePostRequestTypeMutation();
  const [editTypeTemplate] = useUpdateRequestTypeMutation();

  const { data: policiesList, isFetching: isPoliciesListLoading } =
    useGetLeavePolicyQuery({
      page_size: IMPORTANT_VARS.TabelDataPageSize,
    });

  const groupBy = [
    {
      label: "Leaves",
      value: "leave",
    },
    {
      label: "Business Permissions",
      value: "permission",
    },
    // {
    //   label: "Mission",
    //   value: "mission",
    // },
    // {
    //   label: "Transfer",
    //   value: "transfer",
    // },
    // {
    //   label: "Business trip",
    //   value: "trip",
    // },
  ];

  const policiesOptions = [
    { id: null, name: "null" },
    ...(policiesList?.results || []),
  ]?.map((item: any) => {
    return {
      label: item.name,
      value: item.id,
    };
  });

  const leaveTypeOptions = [
    { label: "Paid ", value: "paid" },
    { label: "Unpaid", value: "unpaid" },
  ];
  const rateOptions = [
    { label: "Hour", value: "hour" },
    { label: "Day", value: "day" },
  ];
  const earnAfterOptions = [
    { label: "Month", value: "month" },
    { label: "Day", value: "day" },
  ];

  const yesNoOptions = [
    { label: "Yes", value: 1 },
    { label: "No", value: 0 },
  ];

  const genderOptions = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
    { label: "Both", value: "both" },
  ];

  const onFinish = (values: any) => {
    const data = isUpdate ? { ...values, id: initValues.id } : { ...values };

    const fixedTemplateController = isUpdate
      ? editTypeTemplate
      : createTypeTemplate;

    fixedTemplateController({ ...data })
      .unwrap()
      .then(() => {
        showSuccessMsg({
          msg: isUpdate
            ? "Request created successfully!"
            : "Request added successfully!",
        });
        setIsVisible(false);
      });
  };

  const getInitValues = () => {
    if (!initValues) return undefined;

    const editedValues = {
      ...initValues,
      is_cut_holiday: initValues.is_cut_holiday ? 1 : 0,
      rate_factor: "day",
    };

    return editedValues;
  };

  const validateMaxField = (value: any) => {
    const min_period = form.getFieldValue("min_period");
    const max_period = form.getFieldValue("max_period");
    // if(!min_period || !max_period)
    if (
      min_period < max_period ||
      !(min_period && max_period) ||
      min_period == max_period
    ) {
      return Promise.resolve();
    }
    return Promise.reject("Max cannot be less than Min");
  };
  return (
    <ModalWrapper
      size="large"
      headerTitle="Add New Request"
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      onConfirm={() => form.submit()}
    >
      <div className={`${styles.container} ${containerStyle}`}>
        <div>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={isUpdate ? getInitValues() : undefined}
            // onValuesChange={(_, value) => validateMaxField(value)}
          >
            <div className="pt-5">
              <Form.Item
                name="name"
                label="Request Name"
                rules={[{ required: true }]}
              >
                <TextInput />
              </Form.Item>

              <Form.Item name="code" label="Code" rules={[{ required: true }]}>
                <TextInput />
              </Form.Item>

              <Form.Item name="group" label="Group By">
                <SelectionInput
                  options={groupBy}
                  onChange={(value) =>
                    setRateFactorState(value == "leave" ? "day" : "hour")
                  }
                />
              </Form.Item>

              <Form.Item
                name="type"
                label="Leave Type"
                rules={[{ required: true }]}
              >
                {/* @ts-ignore */}
                <RadioGroupInput options={leaveTypeOptions} />
              </Form.Item>

              <Form.Item
                name="earned_after_amount"
                label="Earned after"
                style={{ display: "inline-block", width: "calc(50% - 8px)" }}
                rules={[{ required: true }]}
              >
                <TextInput isNumberInput />
              </Form.Item>

              <Form.Item
                name="earned_after_factor"
                label=" "
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                  margin: "0 8px",
                }}
                rules={[{ required: true }]}
              >
                <SelectionInput options={earnAfterOptions} />
              </Form.Item>

              <Form.Item
                name="rate_value"
                label="Rate"
                style={{ display: "inline-block", width: "calc(50% - 8px)" }}
              >
                <TextInput isNumberInput />
              </Form.Item>

              <Form.Item
                label="Rate Factor"
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                  margin: "0 8px",
                }}
              >
                <SelectionInput
                  options={rateOptions}
                  disabled={true}
                  placeholder={rateFactorState}
                />
              </Form.Item>

              <Form.Item name="gender" label="Gender">
                {/* @ts-ignore */}
                <RadioGroupInput options={genderOptions} />
              </Form.Item>

              <Form.Item name="is_cut_holiday" label="Cut Holiday/ weekend">
                <RadioGroupInput options={yesNoOptions} />
              </Form.Item>
              <div className="d-flex align-items-center gap-2">
                <Form.Item
                  name="min_period"
                  label="Leave Ability"
                  style={{ width: "100%" }}
                  // style={{ display: "inline-block", width: "calc(33%)" }}
                >
                  <TextInput isNumberInput placeholder="Min" />
                </Form.Item>
                <Form.Item
                  name="max_period"
                  label=" "
                  style={{ width: "100%" }}
                  rules={[{ validator: validateMaxField }]}
                  // style={{ display: "inline-block", width: "calc(33% - 8px)" }}
                >
                  <TextInput isNumberInput placeholder="Max" />
                </Form.Item>
                <Form.Item
                  name="period_factor"
                  label=" "
                  style={{ width: "100%" }}

                  // style={{ display: "inline-block", width: "calc(33% - 8px)" }}
                >
                  <SelectionInput options={rateOptions} placeholder="Factor" />
                </Form.Item>
              </div>

              <Form.Item name="leave_policy" label="Balance Policy">
                <SelectionInput options={policiesOptions} />
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </ModalWrapper>
  );
}
