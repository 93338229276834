import Paper from "components/general/PaperComponent";
import UserInfo from "components/cards/UserInfo";
import COLORS from "constants/Colors";
import { Form, FormRule } from "antd";
import TextInput from "components/inputs/TextInput";
import InfoDetailsCard from "components/general/InfoDetailsCard";
import Button from "components/general/Button";
import SelectionInput from "components/inputs/SelectionInput";
import RadioGroupInput from "components/inputs/RadioGroupInput";
import DatePickerInput from "components/inputs/DatePickerInput";
import {
  useGetEmploymentTypesQuery,
  useGetGendersQuery,
  useGetJobClassificationsQuery,
  useGetMaritalStatusQuery,
  useGetRolesQuery,
  useGetShiftTypesQuery,
  useGetShiftWorkinghoursQuery,
} from "apis/services/other";
import {
  useGetDepartmentsQuery,
  useLazyGetLocationsQuery,
} from "apis/services/locations";
import {
  useAddNewStaffMutation,
  useEditStaffMutation,
  useGetAllStaffQuery,
} from "apis/services/staff";
import showSuccessMsg from "utils/showSuccessMsg";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useGetProjectsQuery } from "apis/services/project";
import moment from "moment";
import AddNewLocationModal from "components/modals/AddNewLocationModal";
import MultiSelection from "components/inputs/MultiSelection";
import { useGetFixedShiftTemplatesQuery } from "apis/services/fixedShiftTemplate";
import styles from "./styles.module.scss";
import { UserFormProps } from "./types";

const phoneRegex = /^01[0125][0-9]{8}$/gm;
const nationalIdRegex = /[0-9]{14}/;

export default function UserForm({ user }: UserFormProps) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [isAddNewLocationModalVisible, setisAddNewLocationModalVisible] =
    useState(false);

  const [form] = Form.useForm();

  const [addNewStaff, { isLoading: isAddingStaffLoading }] =
    useAddNewStaffMutation();

  const [editStaff, { isLoading: isEditStaffLoading }] = useEditStaffMutation();

  const goBack = () => {
    if (state?.canBack) navigate(-1);
    else {
      navigate("/settings/user-access-control/users");
    }
  };

  const onShowAddLocationModal = () => {
    setisAddNewLocationModalVisible(true);
  };

  const onSubmitNewUser = (values: any, forEdit?: boolean) => {
    const request = forEdit ? editStaff : addNewStaff;
    const is_username_touched = form.isFieldTouched("username");
    const request_values = {
      id: user?.id,
      ...values,
      user: {
        ...values,
        is_active: Boolean(values?.is_active),
        first_name: values?.username?.split(" ")?.[0] || "",
        last_name: values?.username?.split(" ")?.[1] || "",
      },
      locations: values.locations?.map((item: any) => item.value),
      mobile_app_enabled: Boolean(values.mobile_app_enabled),
      count_new_balance_for_exceeding_seniors: Boolean(
        values.count_new_balance_for_exceeding_seniors
      ),
      overtime_allowed: Boolean(values.overtime_allowed),
    };
    if (!values.date_of_leaving) {
      request_values.date_of_leaving = null;
    }

    if (!is_username_touched) {
      delete request_values.user.username;
    }

    request(request_values)
      .unwrap()
      .then(() => {
        showSuccessMsg({
          msg: !forEdit
            ? "Add new staff successfully!"
            : "Edit staff successfully",
        });
        goBack();
      });
  };

  const usernameValue = Form.useWatch("username", form);

  const jobTitleValue = Form.useWatch("job_title", form);
  const cfmIdValue = Form.useWatch("cfm_id", form);

  const isSelectedFixedShift = Form.useWatch("default_shift_type", form) === 1;
  const isSelectedBasedShift = Form.useWatch("default_shift_type", form) === 2;
  const isSelectedFlexableShift =
    Form.useWatch("default_shift_type", form) === 3;

  const accountStatusOptions = [
    { label: "Active", value: 1 },
    { label: "Inactive", value: 0 },
  ];
  const enableDisableOptions = [
    { label: "Enable", value: 1 },
    { label: "Disable", value: 0 },
  ];
  const allowDisallowOptions = [
    { label: "Allow", value: 1 },
    { label: "Don't Allow", value: 0 },
  ];

  const { data: roleOptions } = useGetRolesQuery({});
  const { data: jobClassificationOptions } = useGetJobClassificationsQuery({});
  const { data: employeeTypeOptions } = useGetEmploymentTypesQuery({});

  const { data: departments } = useGetDepartmentsQuery({ page_size: 100 });
  const departmentOptions = departments?.results?.map((item: any) => ({
    label: item?.name,
    value: item?.id,
  }));

  const [getLocations] = useLazyGetLocationsQuery();

  const { data: shiftTypeOptions } = useGetShiftTypesQuery({});
  const { data: workinghoursOptions } = useGetShiftWorkinghoursQuery({});
  const numberOfWeekendsOptions = [
    { label: "0 Day", value: 0 },
    { label: "1 Day", value: 1 },
    { label: "2 Days", value: 2 },
  ];

  const { data: genderOptions } = useGetGendersQuery({});
  const { data: maritalStatusOptions } = useGetMaritalStatusQuery({});

  const { data: fixedShiftTemplates } = useGetFixedShiftTemplatesQuery({
    page_size: 100,
  });

  const fixedShiftTemplatesOptions = fixedShiftTemplates?.results?.map(
    (item): any => ({
      label: item?.name,
      value: item?.id,
    })
  );

  const jobTitleLabel = jobTitleValue;

  const { data: managers } = useGetAllStaffQuery({
    role: "manager",
    page_size: 100,
  });
  const adminsOptions = managers?.results?.map((item): any => ({
    label: `${item?.user?.username} (${item?.cfm_id})`,
    value: item?.id,
  }));

  const { data: projects } = useGetProjectsQuery({ page_size: 100 });
  const projectsOptions = projects?.results?.map((item: any): any => ({
    label: item?.name,
    value: item?.id,
  }));

  const getLocationOptions = async (search: string) => {
    return getLocations({ search, page_size: 15 })
      .unwrap()
      .then(
        (res) =>
          res?.results?.map((item: any) => ({
            label: `${item?.name} (${item?.zone?.name})`,
            value: item?.id as number,
            key: `${item?.id}`,
          }))
      );
  };

  const getFormInitialValues = () => {
    return {
      ...user,
      ...(user?.user || {}),

      count_new_balance_for_exceeding_seniors: allowDisallowOptions?.find(() =>
        Number(user?.count_new_balance_for_exceeding_seniors || false)
      )?.value,

      mobile_app_enabled: Number(user?.mobile_app_enabled || false),

      overtime_allowed: Number(user?.overtime_allowed || false),

      is_active: Number(user?.user?.is_active || false),

      gender: genderOptions?.find((item) => item.label === user?.gender)?.value,

      department: user?.department?.id,

      fixed_shift_template: user?.fixed_shift_template,
      role: roleOptions?.find((item) => item.label === user?.role)?.value,

      marital_status:
        maritalStatusOptions?.find(
          (item) => item.label === user?.marital_status
        )?.value || user?.marital_status,

      default_shift_type: shiftTypeOptions?.find(
        (item) => item.label === user?.default_shift_type
      )?.value,

      employment_type: employeeTypeOptions?.find(
        (item) => item.label === user?.employment_type
      )?.value,

      job_classification: jobClassificationOptions?.find(
        (item) => item.label === user?.job_classification
      )?.value,

      reports_to: user?.reports_to?.id,

      default_project: user?.default_project?.id,

      locations: user?.assigned_locations?.map((item: any) => ({
        label: `${item?.name} (${item?.zone?.name})`,
        value: item?.id as number,
        key: `${item?.id}`,
      })),
    };
  };
  const formInitialValues = getFormInitialValues();

  const onCancel = () => {
    goBack();
  };

  const getDateOfLeaveErrorRules = () => {
    const validator: FormRule = ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value) {
          return Promise.resolve();
        }

        if (moment(getFieldValue("date_of_joining")).isBefore(moment(value))) {
          return Promise.resolve();
        }

        return Promise.reject(
          new Error("Date of leaving must be after date of joining!")
        );
      },
    });

    return validator;
  };

  const getConfirmPasswordErrorRule = () => {
    const validator: FormRule = ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value || getFieldValue("password") === value) {
          return Promise.resolve();
        }
        return Promise.reject(
          new Error("The new password that you entered do not match!")
        );
      },
    });

    return validator;
  };

  useEffect(() => {
    if (!user) return;
    if (genderOptions && fixedShiftTemplates && managers) return;
    form.resetFields();
  }, [user, genderOptions, fixedShiftTemplates, managers]);

  useEffect(() => {
    if (isSelectedFixedShift) {
      form.resetFields(["working_hour"]);
    }
  }, [isSelectedFixedShift]);

  return (
    <div>
      <Paper
        borderColor={COLORS.lightGrey}
        paddingHorizontal={44}
        paddingVertical={24}
      >
        <UserInfo
          data={{
            user: {
              first_name: usernameValue || "--",
              last_name: "",
            },
            job_title: jobTitleLabel || "--",
            id: cfmIdValue || "--",
            cfm_id: cfmIdValue || "--",
          }}
          containerStyle={styles.userInfo}
        />
      </Paper>

      <div className="mt-5">
        <Form
          form={form}
          className={styles.form}
          initialValues={formInitialValues}
          onFinish={(values) => {
            onSubmitNewUser(values, !!user);
          }}
          layout="vertical"
          scrollToFirstError
        >
          <InfoDetailsCard cardTitle="Basic information">
            <div className={styles.formGroup}>
              <Form.Item
                name="cfm_id"
                label="Employee ID"
                rules={[{ required: true }]}
              >
                <TextInput />
              </Form.Item>

              <Form.Item
                name="username"
                label="Full Name"
                rules={[{ required: true }]}
              >
                <TextInput />
              </Form.Item>

              <Form.Item
                name="email"
                label="E-mail"
                rules={[{ type: "email" }]}
              >
                <TextInput />
              </Form.Item>

              {!user && (
                <>
                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[{ required: true }]}
                  >
                    <TextInput type="password" />
                  </Form.Item>

                  <Form.Item
                    name="confirm_password"
                    label="Confirm Password"
                    dependencies={["password"]}
                    rules={[{ required: true }, getConfirmPasswordErrorRule()]}
                  >
                    <TextInput type="password" />
                  </Form.Item>
                </>
              )}

              <Form.Item
                name="job_title"
                label="Title"
                rules={[{ required: true }]}
              >
                <TextInput />
              </Form.Item>
            </div>
          </InfoDetailsCard>

          <InfoDetailsCard cardTitle="Account Status">
            <div className={styles.formGroup}>
              <Form.Item
                name="is_active"
                label="Account Status"
                rules={[{ required: true }]}
              >
                <SelectionInput options={accountStatusOptions} />
              </Form.Item>

              <Form.Item
                name="reason_for_change_status"
                label="Reason For Change Status"
              >
                <TextInput type="textarea" />
              </Form.Item>

              <Form.Item
                name="mobile_app_enabled"
                label="Enable mobile application"
              >
                <RadioGroupInput options={enableDisableOptions} />
              </Form.Item>
            </div>
          </InfoDetailsCard>

          <InfoDetailsCard cardTitle="Work Information">
            <div className={styles.formGroup}>
              <Form.Item name="role" label="Role" rules={[{ required: true }]}>
                <SelectionInput options={roleOptions || []} />
              </Form.Item>

              <Form.Item
                name="job_classification"
                label="Job Classification"
                rules={[{ required: true }]}
              >
                <SelectionInput options={jobClassificationOptions || []} />
              </Form.Item>

              <Form.Item
                name="employment_type"
                label="Employment Type"
                rules={[{ required: true }]}
              >
                <SelectionInput options={employeeTypeOptions || []} />
              </Form.Item>

              <Form.Item
                name="date_of_joining"
                label="Date of Joining"
                rules={[{ required: true }]}
              >
                <DatePickerInput />
              </Form.Item>

              <Form.Item
                name="date_of_leaving"
                label="Date of Leaving"
                rules={[getDateOfLeaveErrorRules()]}
              >
                <DatePickerInput />
              </Form.Item>

              <Form.Item
                name="department"
                label="Department"
                rules={[{ required: true }]}
              >
                <SelectionInput options={departmentOptions} />
              </Form.Item>

              <div className={styles.formItemsectionWithButton}>
                <div className="flex-grow-1">
                  <Form.Item
                    name="locations"
                    label="Location"
                    rules={[{ required: true }]}
                  >
                    <MultiSelection fetchOptions={getLocationOptions} />
                  </Form.Item>
                </div>

                <Button onClick={onShowAddLocationModal} isOutline>
                  Add Location
                </Button>
              </div>

              <Form.Item
                name="default_project"
                label="Default Project"
                rules={[{ required: true }]}
              >
                <SelectionInput options={projectsOptions} />
              </Form.Item>

              <Form.Item name="reports_to" label="Reports To">
                <SelectionInput options={adminsOptions || []} />
              </Form.Item>
            </div>
          </InfoDetailsCard>

          <InfoDetailsCard cardTitle="Leaves and Balance">
            <div className={styles.formGroup}>
              <Form.Item
                name="count_new_balance_for_exceeding_seniors"
                label="Count new balance for who exceed the seniority year: "
                rules={[{ required: true }]}
              >
                <RadioGroupInput options={allowDisallowOptions} />
              </Form.Item>

              <Form.Item
                name="seniority_year"
                label="Seniority year"
                rules={[{ required: true }, { type: "number" }]}
              >
                <TextInput isNumberInput />
              </Form.Item>

              <Form.Item
                name="overtime_allowed"
                label="Overtime Allowance"
                rules={[{ required: true }]}
              >
                <RadioGroupInput options={allowDisallowOptions} />
              </Form.Item>
            </div>
          </InfoDetailsCard>

          <InfoDetailsCard cardTitle="Shift Information">
            <div className={styles.formGroup}>
              <Form.Item
                name="default_shift_type"
                label="Shift Type"
                rules={[{ required: true }]}
              >
                <SelectionInput
                  options={
                    shiftTypeOptions?.filter((e) => e.label !== "Noshift") || []
                  }
                />
              </Form.Item>

              <Form.Item name="fixed_shift_template" label="Shift Name">
                <SelectionInput
                  options={fixedShiftTemplatesOptions || []}
                  disabled={isSelectedBasedShift || isSelectedFlexableShift}
                />
              </Form.Item>

              {!user && (
                <Form.Item name="start_date" label="Start Date">
                  <DatePickerInput />
                </Form.Item>
              )}

              <Form.Item name="working_hour" label="Working Hour">
                <SelectionInput
                  disabled={isSelectedFixedShift}
                  options={workinghoursOptions || []}
                />
              </Form.Item>

              <Form.Item name="no_of_weekend" label="No. of Weekend">
                <SelectionInput options={numberOfWeekendsOptions} />
              </Form.Item>
            </div>
          </InfoDetailsCard>

          <InfoDetailsCard cardTitle="Personal Details">
            <div className={styles.formGroup}>
              <Form.Item
                name="phone"
                label="Mobile num"
                rules={[
                  { required: true },
                  { pattern: phoneRegex, message: "Wrong Mobile Number" },
                ]}
              >
                <TextInput />
              </Form.Item>

              <Form.Item
                name="national_id"
                label="National ID"
                rules={[
                  { required: true },
                  { pattern: nationalIdRegex, message: "Wrong National ID" },
                ]}
              >
                <TextInput />
              </Form.Item>

              <Form.Item
                name="dob"
                label="Date of Birth"
                rules={[{ required: true }]}
              >
                <DatePickerInput />
              </Form.Item>

              <Form.Item
                name="gender"
                label="Gender"
                rules={[{ required: true }]}
              >
                <RadioGroupInput options={genderOptions || []} />
              </Form.Item>

              <Form.Item
                name="marital_status"
                label="Marital Status"
                rules={[{ required: true }]}
              >
                <RadioGroupInput options={maritalStatusOptions || []} />
              </Form.Item>
            </div>
          </InfoDetailsCard>

          <div className={styles.formBtnGroup}>
            {!!user && (
              <Button type="button" isOutline onClick={onCancel}>
                Cancel
              </Button>
            )}
            <Button
              type="submit"
              isLoading={isAddingStaffLoading || isEditStaffLoading}
            >
              {!user ? "Add" : "Save"}
            </Button>
          </div>
        </Form>
      </div>

      <AddNewLocationModal
        isVisible={isAddNewLocationModalVisible}
        setIsVisible={setisAddNewLocationModalVisible}
      />
    </div>
  );
}
