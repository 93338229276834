import api from "apis";
import { PaginatedResponse, PaginationParams } from "apis/types/general";
import { RequestProps, TransferRequestProps } from "apis/types/requests";
import getSerializedQueryArgs from "utils/getSerializedQueryArgs";

export const departmentApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMyRequest: build.query<
      PaginatedResponse<RequestProps>,
      PaginationParams
    >({
      query: (params) => ({
        url: "/requests/all_requests/list_my_requests/",
        params,
      }),
      providesTags: ["list_my_requests"],
      serializeQueryArgs: getSerializedQueryArgs,
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.page !== previousArg?.page;
      },
    }),
    getStaffRequest: build.query<
      PaginatedResponse<RequestProps>,
      PaginationParams
    >({
      query: (params) => ({
        url: "/requests/all_requests/list_staff_requests/",
        params,
      }),
      providesTags: ["list_staff_requests", "Requests"],
      serializeQueryArgs: getSerializedQueryArgs,
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.page !== previousArg?.page;
      },
    }),

    getMyBusinessPermissionRequests: build.query<
      PaginatedResponse<RequestProps>,
      PaginationParams
    >({
      query: (params) => ({
        url: "/requests/business_permission_requests/list_my_requests/",
        params,
      }),
      providesTags: ["business_permission_requests"],
      serializeQueryArgs: getSerializedQueryArgs,
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.page !== previousArg?.page;
      },
    }),

    getMyBusinessTripRequests: build.query<
      PaginatedResponse<RequestProps>,
      PaginationParams
    >({
      query: (params) => ({
        url: "/requests/business_trip_requests/list_my_requests/",
        params,
      }),
      providesTags: ["business_trip_requests"],
      serializeQueryArgs: getSerializedQueryArgs,
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.page !== previousArg?.page;
      },
    }),

    getMyLeaveRequests: build.query<
      PaginatedResponse<RequestProps>,
      PaginationParams
    >({
      query: (params) => ({
        url: "/requests/leave_requests/list_my_requests/",
        params,
      }),
      providesTags: ["leave_requests"],
      serializeQueryArgs: getSerializedQueryArgs,
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.page !== previousArg?.page;
      },
    }),

    getMyMissionRequests: build.query<
      PaginatedResponse<RequestProps>,
      PaginationParams
    >({
      query: (params) => ({
        url: "/requests/mission_requests/list_my_requests/",
        params,
      }),
      providesTags: ["mission_requests"],
      serializeQueryArgs: getSerializedQueryArgs,
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.page !== previousArg?.page;
      },
    }),

    getMyTransferRequests: build.query<
      PaginatedResponse<RequestProps>,
      PaginationParams
    >({
      query: (params) => ({
        url: "/requests/transfer_requests/list_my_requests/",
        params,
      }),
      providesTags: ["transfer_requests"],
      serializeQueryArgs: getSerializedQueryArgs,
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.page !== previousArg?.page;
      },
    }),

    getStaffBusinessPermissionRequests: build.query<
      PaginatedResponse<RequestProps>,
      PaginationParams
    >({
      query: (params) => ({
        url: "/requests/business_permission_requests/list_staff_requests/",
        params,
      }),
      providesTags: ["business_permission_requests"],
      serializeQueryArgs: getSerializedQueryArgs,
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.page !== previousArg?.page;
      },
    }),

    getStaffBusinessTripRequests: build.query<
      PaginatedResponse<RequestProps>,
      PaginationParams
    >({
      query: (params) => ({
        url: "/requests/business_trip_requests/list_staff_requests/",
        params,
      }),
      providesTags: ["business_trip_requests"],
      serializeQueryArgs: getSerializedQueryArgs,
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.page !== previousArg?.page;
      },
    }),

    getStaffLeaveRequests: build.query<
      PaginatedResponse<RequestProps>,
      PaginationParams
    >({
      query: (params) => ({
        url: "/requests/leave_requests/list_staff_requests/",
        params,
      }),
      providesTags: ["leave_requests"],
      serializeQueryArgs: getSerializedQueryArgs,
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.page !== previousArg?.page;
      },
    }),

    getStaffMissionRequests: build.query<
      PaginatedResponse<RequestProps>,
      PaginationParams
    >({
      query: (params) => ({
        url: "/requests/mission_requests/list_staff_requests/",
        params,
      }),
      providesTags: ["mission_requests"],
      serializeQueryArgs: getSerializedQueryArgs,
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.page !== previousArg?.page;
      },
    }),

    getStaffTransferRequests: build.query<
      PaginatedResponse<RequestProps>,
      PaginationParams
    >({
      query: (params) => ({
        url: "/requests/transfer_requests/list_staff_requests/",
        params,
      }),
      providesTags: ["transfer_requests"],
      serializeQueryArgs: getSerializedQueryArgs,
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.page !== previousArg?.page;
      },
    }),

    createRequest: build.mutation({
      query: (data) => ({
        url: `/requests/${data.URL_request_type}/${
          data.method === "PATCH" ? `${data.id}/` : ""
        }`,
        method: data.method,
        body: data.formdata ?? data,
      }),
      invalidatesTags: (res, err, arg) => [
        "Requests",
        { type: arg.request_type, id: arg.id },
        "Shift", // just added ( attempting to rerender after conflict resolve )
      ],
    }),

    rejectRequest: build.mutation({
      query: (data) => ({
        url: `/requests/${data.request_type}/${data.id}/reject_request/`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: (res, err, arg) => [
        "Requests",
        { type: arg.request_type, id: arg.id },
      ],
    }),

    approveRequest: build.mutation({
      query: (data) => ({
        url: `/requests/all_requests/${data.id}/approve_request/`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: (res, err, arg) => [
        "Requests",
        {
          type: "Requests",
          id: arg?.id,
        },
      ],
    }),

    updateRequest: build.mutation({
      query: (data) => ({
        url: `/requests/all_requests/${data.id}/${data.request_type}/`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: (res, err, arg) => [
        "Requests",
        { type: arg.type, id: arg.id },
      ],
    }),

    updateTransferRequest: build.mutation({
      query: (data) => ({
        url: `/requests/transfer_requests/${data.id}/`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: (res, err, arg) => [
        { type: "transfer_requests", id: arg.id },
      ],
    }),
    updateDelegation: build.mutation({
      query: (data) => ({
        url: `/requests/transfer_requests/${data.id}/delegate_request/`,
        method: "PATCH",
        body: data.values,
      }),
    }),
    updateDelegationForAllTypes: build.mutation({
      query: (data) => ({
        url: `/requests/all_requests/${data.id}/delegate_request/`,
        method: "PATCH",
        body: data.values,
      }),
      invalidatesTags: ["list_staff_requests"],
    }),
    getTransferRequestMoreDetails: build.query<
      TransferRequestProps,
      Partial<RequestProps>
    >({
      query: (data) => ({
        url: `/requests/transfer_requests/${data.id}/`,
      }),
      providesTags: (res, err, arg) => [
        { type: "transfer_requests", id: arg.id },
      ],
    }),
    getRequest: build.query({
      query: (id) => ({
        url: `/requests/all_requests/${id}/`,
      }),
      providesTags: (req, err, arg) => [{ type: "Requests", id: arg }],
    }),
    getPermissionMissingTime: build.query({
      query: (params) => ({
        url: `/missing-time/`,
        params,
      }),
      // providesTags: (req, err, arg) => [{ type: "Requests", id: arg }],
    }),
    getRequestCategories: build.query({
      query: (params) => ({
        url: `/categories/`,
        params,
      }),
    }),
  }),
});

export const {
  useGetMyRequestQuery,
  useGetStaffRequestQuery,
  useUpdateRequestMutation,

  useGetMyBusinessPermissionRequestsQuery,
  useGetMyBusinessTripRequestsQuery,
  useGetMyLeaveRequestsQuery,
  useGetMyMissionRequestsQuery,
  useGetMyTransferRequestsQuery,

  useGetStaffBusinessPermissionRequestsQuery,
  useGetStaffBusinessTripRequestsQuery,
  useGetStaffLeaveRequestsQuery,
  useGetStaffMissionRequestsQuery,
  useGetStaffTransferRequestsQuery,

  useApproveRequestMutation,
  useRejectRequestMutation,
  useCreateRequestMutation,

  useGetTransferRequestMoreDetailsQuery,
  useUpdateDelegationMutation,
  useUpdateDelegationForAllTypesMutation,
  useUpdateTransferRequestMutation,

  useGetRequestQuery,
  useLazyGetRequestQuery,

  useLazyGetPermissionMissingTimeQuery,

  useGetRequestCategoriesQuery,
} = departmentApi;
