import api from "apis";
import { OptionsType } from "apis/types/other";
import getSerializedQueryArgs from "utils/getSerializedQueryArgs";
import infintyPaginationMergeHandler from "utils/infintyPaginationMergeHandler";

const optionsTransformFn = (response: any): OptionsType[] => {
  if (response?.results) {
    return response?.results?.map((item: any) => ({
      label: item?.name,
      value: item?.id,
    }));
  }

  return Object.keys(response).map((item) => ({
    label: response[item],
    value: +item,
  }));
};

export const otherApi = api.injectEndpoints({
  endpoints: (build) => ({
    getEmploymentTypes: build.query({
      query: (params) => ({
        url: "/locations/employment_types/",
        params,
      }),
      transformResponse: optionsTransformFn,
    }),

    getGenders: build.query({
      query: (params) => ({
        url: "/locations/genders/",
        params,
      }),
      transformResponse: optionsTransformFn,
    }),

    getJobClassifications: build.query({
      query: (params) => ({
        url: "/locations/job_classifications/",
        params,
      }),
      transformResponse: optionsTransformFn,
    }),

    getJobTitles: build.query({
      query: (params) => ({
        url: "/locations/job_titles/",
        params,
      }),
      transformResponse: optionsTransformFn,
    }),

    getMaritalStatus: build.query({
      query: (params) => ({
        url: "/locations/marital_status/",
        params,
      }),
      transformResponse: optionsTransformFn,
    }),

    getRoles: build.query({
      query: (params) => ({
        url: "/locations/roles/",
        params,
      }),
      transformResponse: optionsTransformFn,
    }),

    getShiftTypes: build.query({
      query: (params) => ({
        url: "/shifts/shift_types/",
        params,
      }),
      transformResponse: optionsTransformFn,
    }),

    getShiftWorkinghours: build.query({
      query: (params) => ({
        url: "/shifts/working_hours/",
        params,
      }),
      transformResponse: optionsTransformFn,
    }),
    getPublicHolidays: build.query({
      query: (params) => ({
        url: "/shifts/public_holidays/",
        params,
      }),
      providesTags: ["PublicHoliday"],
    }),
    postPublicHoliday: build.mutation({
      query: (body) => ({
        url: "/shifts/public_holidays/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["PublicHoliday"],
    }),
    updatePublicHoliday: build.mutation({
      query: (body) => ({
        url: `/shifts/public_holidays/${body.id}/`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["PublicHoliday"],
    }),
    deletePublicHoliday: build.mutation({
      query: (body) => ({
        url: `/shifts/public_holidays/${body.id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["PublicHoliday"],
    }),
    getActivities: build.query({
      query: (params) => ({
        url: "/activity/",
        params: { ...params, page_size: 15 },
      }),
      providesTags: ["Activities"],
      serializeQueryArgs: getSerializedQueryArgs,
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.page !== previousArg?.page;
      },
      merge: infintyPaginationMergeHandler,
    }),
    // Leave policy
    getLeavePolicy: build.query({
      query: (params) => ({
        url: `/leave-policy/`,
        params,
      }),
      providesTags: ["setup-policy"],
    }),
    postLeavePolicy: build.mutation({
      query: (body) => ({
        url: `/leave-policy/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["setup-policy"],
    }),
    updateLeavePolicy: build.mutation({
      query: (body) => ({
        url: `/leave-policy/${body.id}/`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["setup-policy"],
    }),
    deleteLeavePolicy: build.mutation({
      query: (id) => ({
        url: `/leave-policy/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["setup-policy"],
    }),
    // Leave Balance
    getLeaveBalance: build.query({
      query: (params) => ({
        url: `/leave-balance/stats/?cfm_staff=${params.cfm_id}`,
        method: "GET",
        params: params.params,
      }),
      providesTags: ["balance-by-policy"],
    }),
    // Leave Balance policy
    getLeaveBalancePolicy: build.query({
      query: (params) => ({
        url: `/leave-policy-balance/?leave_policy=${params.policy}`,
        method: "GET",
        params: params.params,
      }),
      providesTags: ["balance-by-policy"],
    }),
    postLeaveBalancePolicy: build.mutation({
      query: (body) => ({
        url: `/leave-policy-balance/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["balance-by-policy"],
    }),
    updateLeaveBalancePolicy: build.mutation({
      // @ts-ignore
      query: (body) => ({
        url: `/leave-policy-balance/${body.id}/`,
        method: "PATCH",
        body: body.data,
      }),
      invalidatesTags: ["balance-by-policy"],
    }),
    deleteLeaveBalancePolicy: build.mutation({
      // @ts-ignore
      query: (body) => ({
        url: `/leave-policy-balance/${body.id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["balance-by-policy"],
    }),

    // List of Requests
    getRequestsSetup: build.query({
      query: (params) => ({
        url: `/leave-type/`,
        params,
      }),
      providesTags: ["list_requests"],
    }),
    postRequestType: build.mutation({
      query: (body) => ({
        url: `/leave-type/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["list_requests"],
    }),
    updateRequestType: build.mutation({
      query: (body) => ({
        url: `/leave-type/${body.id}/`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["list_requests"],
    }),
    deleteRequestType: build.mutation({
      query: (id) => ({
        url: `/leave-type/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["list_requests"],
    }),
    getPermissionTypes: build.query({
      query: (params) => ({
        url: `/leave-type/`,
        params,
      }),
      providesTags: ["list_requests"],
      transformResponse: optionsTransformFn,
    }),
    exportStaffShift: build.query({
      query: () => ({
        url: `/my_staff_shifts/export/`,
      }),
    }),
    exportStaffcheckin: build.query({
      query: () => ({
        url: `/my_staff_checkins/export/`,
      }),
    }),
    getCalenderLeaveBalance: build.query({
      query: (params) => ({
        url: `/leave-balance/users_balance/`,
        params,
      }),
    }),
    getLeaveBalanceReport: build.query({
      query: (params) => ({
        url: `/leave-balance/report/`,
        params,
      }),
    }),
    getReportFile: build.query({
      query: (params) => ({
        url: params.url,
        params,
      }),
      transformResponse: optionsTransformFn,
    }),
  }),
});

export const {
  useGetEmploymentTypesQuery,
  useGetGendersQuery,
  useGetJobClassificationsQuery,
  useGetJobTitlesQuery,
  useGetMaritalStatusQuery,
  useGetRolesQuery,
  useGetShiftTypesQuery,
  useGetShiftWorkinghoursQuery,
  usePostPublicHolidayMutation,
  useUpdatePublicHolidayMutation,
  useGetPublicHolidaysQuery,
  useDeletePublicHolidayMutation,
  useGetActivitiesQuery,
  useLazyGetActivitiesQuery,
  useGetLeavePolicyQuery,
  usePostLeavePolicyMutation,
  useUpdateLeavePolicyMutation,
  useDeleteLeavePolicyMutation,
  useGetLeaveBalancePolicyQuery,
  usePostLeaveBalancePolicyMutation,
  useUpdateLeaveBalancePolicyMutation,
  useDeleteLeaveBalancePolicyMutation,
  useGetRequestsSetupQuery,
  usePostRequestTypeMutation,
  useUpdateRequestTypeMutation,
  useDeleteRequestTypeMutation,
  useGetPermissionTypesQuery,
  useExportStaffShiftQuery,
  useExportStaffcheckinQuery,
  useGetLeaveBalanceQuery,
  useGetCalenderLeaveBalanceQuery,
  useGetLeaveBalanceReportQuery,
  useLazyGetReportFileQuery,
} = otherApi;
