/* eslint-disable */
import { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import {
  useGetTransferRequestMoreDetailsQuery,
  useUpdateDelegationForAllTypesMutation,
  // useUpdateDelegationMutation,
  useUpdateRequestMutation,
} from "apis/services/requests";
import AVATAR_LOGO from "assets/images/user-Avata.png";
import TextInput from "components/inputs/TextInput";
import Button from "components/general/Button";
import Paper from "components/general/PaperComponent";
import UserInfo from "components/cards/UserInfo";
import Image from "components/general/Image";
import Text from "components/general/Text";
import DebouncedSearch from "components/inputs/DebouncedSearch";
import StatusBadge from "components/general/StatusBadge";
import showSuccessMsg from "utils/showSuccessMsg";
import InfoRow from "components/general/InfoRow";
import { ManageRequestProps } from "./types";
import { IoMdCheckmark } from "react-icons/io";
import ModalWrapper from "../ModalWrapper";
import CreateRequest from "../requests";
import { useGetAllStaffQuery } from "apis/services/staff";
import styles from "./styles.module.scss";
import COLORS from "constants/Colors";
import { Spin } from "antd";
import HandleErrors from "utils/handleErrors";
import { useSelector } from "react-redux";
import { RootState } from "reducers";
import { useGetProjectsQuery } from "apis/services/project";
import useGetUserInfo from "hooks/useGetUserInfo";
import { useGetZonesQuery } from "apis/services/locations";

// const RequestsType = {
//   0: "mission_requests",
//   1: "transfer_requests",
//   2: "leave_requests",
//   3: "business_trip_requests",
//   4: "business_permission_requests",
// };

function MemberUpdatedAt({ user }: { user: any }) {
  const locations = user?.created_at;
  if (locations) {
    return (
      <Text className={styles.memberUpdatedDate}>
        {moment(locations).format("MMM DD, YYYY")} at{" "}
        {moment(locations).format("hh:mm a")}
      </Text>
    );
  }
  return <> </>;
}

export default function ManageRequest({
  containerStyle,
  isVisible,
  setIsVisible,
  request,
  transferAllocations,
  is_my_request,
}: ManageRequestProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [requestCancelation, setRequestCancelation] = useState(false);
  const [reason, setReason] = useState("");
  const [isEditModalVisible, setisEditModalVisible] = useState("");
  const [showdelegate, setshowdelegate] = useState(false);
  const [delegateSelection, setdelegateSelection] = useState({});
  const userData = useSelector((state: RootState) => state.auth.userData);

  const { role, userInfo } = useGetUserInfo();
  const { data: zones } = useGetZonesQuery({ page_size: 100 });

  const { data: requestMoreDetails } = useGetTransferRequestMoreDetailsQuery(
    request,
    { skip: request.request_type !== "transfer" }
  );

  const { data: managers, isFetching: managersLoader } = useGetAllStaffQuery({
    page_size: 100,
    page: Number(searchParams.get("managers_page") ?? "1"),
    search: searchParams.get("managers_search") ?? "",
    role: "manager",
  });
  let filterMangerns = managers?.results?.filter((manager: any) => {
    return manager.id !== userData?.id;
  });

  const { data: projects } = useGetProjectsQuery(
    {
      location: requestMoreDetails?.location?.id,
      page_size: 100,
    },
    {
      skip: !requestMoreDetails?.location,
    }
  );

  const adminsOptions = managers?.results?.map((item: any) => ({
    label: `${item?.user?.username} (${item?.cfm_id})`,
    value: item?.id,
  }));
  const zoneOptions = zones?.results?.map((item: any) => ({
    label: item.name,
    value: item.id,
  }));
  const projectsOptions = projects?.results?.map((item: any) => ({
    label: item.name,
    value: item.id,
    projectId: item.project_id,
    categoryId: item?.category?.name,
  }));

  const getReportToName = (value: string) =>
    adminsOptions?.find((item) => +value === item.value)?.label;
  const getZoneName = (value: string) =>
    zoneOptions?.find((item: any) => +value === +item.value)?.label;
  const getProjectIdName = (value: string) =>
    projectsOptions?.find((item: any) => +value === item.value)?.projectId;
  const getCategoryNameFromProject = (projectId: string) =>
    projectsOptions?.find((item: any) => +projectId === item.value)?.categoryId;

  const [updateDelegation, { isLoading: delegationLoading }] =
    useUpdateDelegationForAllTypesMutation();
  const [updateRequest, { isLoading }] = useUpdateRequestMutation();

  useEffect(() => {
    setRequestCancelation(false);
    if (isVisible == false) {
      setshowdelegate(false);
      searchParams.set("managers_search", "");
      searchParams.set("managers_page", "1");
    }
  }, [isVisible]);

  const onDelegateRequest = () => {
    const requestValues = {
      // @ts-ignore
      id: request?.id,
      // @ts-ignore
      values: { delegated_to_id: delegateSelection?.id },
    };
    updateDelegation(requestValues)
      .unwrap()
      .then(() => {
        showSuccessMsg({ msg: "Request has been delegated successfully!" });
        searchParams.set("managers_search", "");
        setIsVisible(false);
      });
  };

  const onUpdateRequest = (request_type: string) => {
    updateRequest({
      id: request?.id,
      status: 0,
      type: is_my_request ? "list_my_requests" : "list_staff_requests",
      request_type,
      reason,
    })
      .unwrap()
      .then(() => {
        showSuccessMsg({ msg: "Request has been updated successfully!" });
        setIsVisible(false);
      });
  };

  const handleDelegateSelection = (item: any) => {
    console.log(item, "selection");
    // @ts-ignore
    if (item.id === delegateSelection?.id) {
      setdelegateSelection({});
    } else {
      setdelegateSelection(item);
    }
  };

  const onSearch = (value: string) => {
    searchParams.set("managers_search", value);
    searchParams.set("managers_page", "1");
    setSearchParams(searchParams);
  };

  const onEditRequest = (type: string) => {
    setisEditModalVisible(type);
  };

  const NormalRequestDetailsMarkup = (
    <div className="mb-4">
      <Paper
        backgroundColor="#F0F0F040"
        borderColor="#F0F0F040"
        paddingHorizontal={24}
        paddingVertical={18}
      >
        <div className="my-3">
          <Text
            className={`${styles.headerLbl}`}
          >{`Request Type: ${request?.request_type} ${
            request?.leave_type_name ? " - " + request?.leave_type_name : ""
          }`}</Text>
          <Text className="d-block">{request?.reason}</Text>
        </div>
        <div className="my-3">
          <Text className={`${styles.headerLbl}`}>Request Date:</Text>
          {request?.date && (
            <>
              {request?.from_time && (
                <Text className="d-block">
                  From: {request?.date} at {request?.from_time}
                </Text>
              )}
              {request?.from_time && (
                <Text className="d-block">
                  To: {request?.date} at {request?.to_time}
                </Text>
              )}
            </>
          )}
          {request?.start_date && (
            <Text className="d-block">From: {request?.start_date}</Text>
          )}
          {request?.end_date && (
            <Text className="d-block">To: {request?.end_date}</Text>
          )}
        </div>
      </Paper>
    </div>
  );
  console.log(requestMoreDetails, "requestMoreDetails?.location");

  const TransferAllocationMarkup = (
    <div className="mb-4">
      {transferAllocations?.map((item) => (
        <Paper
          paddingHorizontal={24}
          paddingVertical={18}
          backgroundColor="#F0F0F040"
          borderColor="#D9D9D980"
        >
          <div className="mb-2">
            <InfoRow
              title="Project ID:"
              infoFontWeight="700"
              titleFontWeight="700"
              titleFontSize={24}
              infoFontSize={24}
              // titleColor={"grey"}
              // @ts-ignore
              info={getProjectIdName(item?.project) || "--"}
              containerStyle="justify-content-start"
            />
          </div>

          <InfoRow
            title="Start Date:"
            infoFontWeight="600"
            titleFontWeight="600"
            titleColor={"grey"}
            info={request.start_date || "--"}
            containerStyle="justify-content-start"
          />
          <InfoRow
            title="Zone:"
            infoFontWeight="600"
            titleFontWeight="600"
            titleColor={"grey"}
            // @ts-ignore
            info={getZoneName(requestMoreDetails?.zone) || "--"}
            containerStyle="justify-content-start"
          />
          <InfoRow
            title="Locations:"
            infoFontWeight="600"
            titleFontWeight="600"
            titleColor={"grey"}
            // @ts-ignore
            info={
              requestMoreDetails?.location
                ?.map((item: any) => item.name)
                .join() || "--"
            }
            containerStyle="justify-content-start"
          />
          <InfoRow
            title="Report To:"
            infoFontWeight="600"
            titleFontWeight="600"
            titleColor={"grey"}
            info={
              // @ts-ignore
              getReportToName(`${requestMoreDetails?.new_reports_to}`) || "--"
            }
            containerStyle="justify-content-start"
          />
          <InfoRow
            title="Category ID:"
            infoFontWeight="600"
            titleFontWeight="600"
            titleColor={"grey"}
            info={getCategoryNameFromProject(`${item.project}`)}
            containerStyle="justify-content-start"
          />
          <InfoRow
            title="Percentage:"
            infoFontWeight="600"
            titleFontWeight="600"
            titleColor={"grey"}
            info={`${item?.percentage}%` || "--"}
            containerStyle="justify-content-start"
          />
        </Paper>
      ))}
    </div>
  );
  console.log(request, "user");

  return (
    <ModalWrapper
      size="large"
      headerTitle={showdelegate ? "Delegate" : request?.request_type ?? ""}
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      isFooterHidden
    >
      <div className={`${styles.container} ${containerStyle}`}>
        {showdelegate ? (
          <div className="mb-4">
            <DebouncedSearch
              defaultValue={searchParams.get("managers_search") ?? ""}
              onSearchChange={onSearch}
              placeholder="Search for Members"
            />
            {managersLoader ? (
              <div className="d-flex my-5">
                <Spin className="m-auto" />
              </div>
            ) : (
              <>
                <div>
                  {filterMangerns?.map((manager) => (
                    <div
                      className="d-flex align-items-center justify-content-between pointer p-4 px-5 my-3"
                      style={{
                        borderRadius: 20,
                        border: `1px solid ${
                          // @ts-ignore
                          manager.id == delegateSelection?.id
                            ? COLORS.primary
                            : COLORS.lightGrey
                        }`,
                      }}
                      onClick={() => handleDelegateSelection(manager)}
                    >
                      <UserInfo data={manager} />
                      {/* @ts-ignore */}
                      {manager.id == delegateSelection?.id && (
                        <IoMdCheckmark size={36} color={COLORS.primary} />
                      )}
                    </div>
                  ))}
                </div>
              </>
            )}
            <div className="m-auto mt-4 w-25">
              <Button
                btnClassName="m-auto w-100 justify-content-center"
                // @ts-ignore
                disabled={!delegateSelection?.id}
                isLoading={delegationLoading}
                onClick={onDelegateRequest}
              >
                Delegate
              </Button>
            </div>
          </div>
        ) : requestCancelation ? (
          <div className="pt-5">
            <div className="pb-5 text-center">
              <Text className={styles.headerTitle}>
                Are you sure you want to Cancel {request?.request_type} Request
                from “{request?.start_date || request?.date} - to{" "}
                {request?.end_date || request?.date}”
              </Text>
            </div>

            <TextInput
              value={reason}
              onChange={(e) => setReason(e.target?.value)}
              placeholder="Reason “Option”"
              type="textarea"
            />

            <div className={`mt-5 ${styles.buttonsGroup}`}>
              <Button
                isLoading={isLoading}
                onClick={() => onUpdateRequest("cancel_request")}
              >
                Confirm
              </Button>
              <Button
                isOutline
                isLoading={isLoading}
                onClick={() => setIsVisible(false)}
              >
                Keep the request
              </Button>
            </div>
          </div>
        ) : (
          <>
            <div className={`${styles.memberContent} mt-5 mb-4`}>
              <Paper
                backgroundColor="#F0F0F040"
                borderColor="#D9D9D980"
                paddingHorizontal={24}
                paddingVertical={18}
              >
                <div className={styles.memberInfo}>
                  <div className={styles.avatar}>
                    <Image src={AVATAR_LOGO} alt="User Avatar" />
                  </div>
                  <div>
                    {!is_my_request ? (
                      <>
                        <Text
                          className={styles.memberName}
                        >{`${request?.created_for?.user?.first_name} ${request?.created_for?.user?.last_name} Sent ${request?.request_type} Request`}</Text>
                        <Text
                          className={styles.memberUpdatedDate}
                          style={{ display: "block" }}
                        >
                          cfm id: {request.created_for.cfm_id}
                        </Text>
                        <Text
                          className={styles.memberUpdatedDate}
                          style={{ display: "block" }}
                        >
                          request id: {request.id}
                        </Text>
                      </>
                    ) : (
                      <>
                        <Text
                          className={styles.memberName}
                        >{`You have Sent ${request?.request_type} Request`}</Text>
                        <Text
                          className={styles.memberUpdatedDate}
                          style={{ display: "block" }}
                        >
                          request id: {request.id}
                        </Text>
                      </>
                    )}

                    <MemberUpdatedAt user={request} />
                  </div>
                </div>
                <StatusBadge
                  color={request?.status === "accepted" ? "success" : "warning"}
                  text={request?.status}
                  containerStyle={styles.statusBadge}
                />
              </Paper>
            </div>

            {!request?.changes_applied && is_my_request && (
              <div className={`${styles.requested_to} mb-4`}>
                <Paper
                  backgroundColor="#F0F0F040"
                  borderColor="#F0F0F040"
                  paddingHorizontal={24}
                  paddingVertical={18}
                >
                  <UserInfo data={request?.created_by} hideImage />
                  <MemberUpdatedAt user={request?.created_by} />
                </Paper>
              </div>
            )}

            {transferAllocations?.length && request?.request_type === "transfer"
              ? TransferAllocationMarkup
              : NormalRequestDetailsMarkup}

            <div className={styles.buttonsGroup}>
              {["pending", "accepted"].includes(
                request?.status ? request?.status : ""
              ) &&
                ((!is_my_request || role !== "employee") &&
                request?.status === "pending" &&
                (!request?.delegated_to.length ||
                  request?.delegated_to.at(-1) === userInfo?.id) ? (
                  <>
                    <Button
                      isLoading={isLoading}
                      onClick={() => onUpdateRequest("approve_request")}
                    >
                      Accept
                    </Button>
                    <Button
                      isOutline
                      isLoading={isLoading}
                      onClick={() => setshowdelegate(true)}
                    >
                      Delegate
                    </Button>
                    {["transfer", "mission"].includes(
                      request?.request_type || ""
                    ) && (
                      <Button
                        isOutline
                        isLoading={isLoading}
                        onClick={() => onEditRequest(request?.request_type)}
                      >
                        Edit
                      </Button>
                    )}
                    <Button
                      isOutline
                      isLoading={isLoading}
                      onClick={() => onUpdateRequest("reject_request")}
                      btnClassName="border-0"
                    >
                      Reject
                    </Button>

                    <CreateRequest
                      isVisible={!!isEditModalVisible}
                      setIsVisible={setisEditModalVisible}
                      type={isEditModalVisible}
                      isUpdate
                      request={{
                        ...request,
                        transfer_allocations: transferAllocations,
                      }}
                    />
                  </>
                ) : (
                  is_my_request &&
                  request?.status !== "accepted" && (
                    <Button
                      isOutline
                      isLoading={isLoading}
                      onClick={() => setRequestCancelation(true)}
                    >
                      Cancel {request?.request_type}
                    </Button>
                  )
                ))}
            </div>
          </>
        )}
      </div>
    </ModalWrapper>
  );
}
