import UserInfo from "components/cards/UserInfo";
import SecondaryPageHeader from "components/general/SecondaryPageHeader";
import Table from "components/general/Table";
import { useGetNewStaffOvertimesQuery } from "apis/services/overtime";
import useGetUserInfo from "hooks/useGetUserInfo";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import StatusBadge from "components/general/StatusBadge";
// import StatusView from "components/pages/home/status-view";

export default function IntegrationTransactions() {
  const [searchParams] = useSearchParams();
  const { role } = useGetUserInfo();

  const { data, isFetching: isEmployeeShiftsLoading } =
    useGetNewStaffOvertimesQuery(
      {
        page: searchParams.get("page") ?? 1,
        search: searchParams.get("search") ?? "",
        status: searchParams.get("status") || undefined,
        start_date: searchParams.get("startDate") ?? "",
        end_date: searchParams.get("endDate") ?? "",
      },
      { skip: !role }
    );

  const changeStaffData = (item: any): any => ({
    id: String(item.id),
    rowData: [
      {
        type: "element",
        dataItem: (
          <UserInfo
            data={
              {
                ...item,
                user: {
                  first_name: item.username.split(" ")?.[0] || "",
                  last_name: item.username.split(" ")?.[1] || "",
                },
              } ?? item.conflict?.employee
            }
            isTableCol
          />
        ),
      },
      {
        type: "text",
        dataItem: String(moment(item?.created_at).format("DD-MM-YYYY")),
      },
      {
        type: "text",
        dataItem: String(item.id),
      },
      {
        type: "text",
        dataItem: (
          <StatusBadge
            iconType={item?.is_synced ? "success" : "fail"}
            color={item?.is_synced ? "success" : "warning"}
          />
        ),
      },
    ],
  });
  return (
    <div>
      <SecondaryPageHeader title="Transactions" />

      <Table
        isCheckBoxVisible={false}
        showSearchFilter
        headers={["Members ID", "Date", "Request ID", "Is Synced"]}
        data={data?.results?.map((item: any) => changeStaffData(item)) ?? []}
        isLoading={isEmployeeShiftsLoading}
        count={data?.count}
      />
    </div>
  );
}
