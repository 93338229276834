import Image from "components/general/Image";
import CFM_LOGO from "assets/images/cfm-logo.png";
import AVATAR_LOGO from "assets/images/user-Avata.png";
import logoutHandler from "utils/logoutHandler";
import { useSelector } from "react-redux";
import { RootState } from "reducers";
import Menu from "components/Menu";
import { Link } from "react-router-dom";
import NOTIFICATION from "../../../assets/icons/notification.svg";
import styles from "./styles.module.scss";

export default function Navbar() {
  const userData = useSelector((state: RootState) => state.auth.userData);
  const onLogout = () => {
    logoutHandler();
  };

  const MenuData = [
    {
      key: "profile",
      label: (
        <a rel="noopener noreferrer" href="/my-profile">
          My Profile
        </a>
      ),
    },
    {
      key: "logout",
      label: (
        <button className={styles.logoutButton} onClick={onLogout}>
          Logout
        </button>
      ),
      onClick: onLogout,
    },
  ];

  return (
    <nav className={styles.navbar}>
      <div className={styles.logo}>
        <Image src={CFM_LOGO} alt="Logo" />
      </div>

      <div className={styles["user-info"]}>
        <Link to="/notifications">
          <div className={styles.notification}>
            <Image src={NOTIFICATION} alt="User Avatar" />
          </div>
        </Link>
        <div className={styles.avatar}>
          <Image src={AVATAR_LOGO} alt="User Avatar" />
        </div>

        <Menu items={MenuData}>
          <div className={styles["user-dropdown"]}>
            <span className={styles.username}>
              {userData?.user?.first_name} {userData?.user?.last_name}
            </span>
            <div className={styles["gg-chevron-down"]} />
          </div>
        </Menu>
      </div>
    </nav>
  );
}
